import * as React from "react";
import Redirect from "../../../../components/redirect";
//--------------------------------------------------

  const url = "https://www.mpweekly.com/culture/%E8%B7%AF%E9%81%8E%E5%8C%97%E8%A7%92-%E5%8C%97%E8%A7%92%E6%B3%B3%E6%A3%9A-%E4%B8%83%E5%A7%8A%E5%A6%B9%E6%B3%B3%E6%A3%9A-188616";

//--------------------------------------------------
// common config below this line, no need to change
const IndexPage = () => ( <Redirect goto_url={url} /> );
export default IndexPage;
